import React from "react";
import { Admin, Resource, Login, defaultTheme } from "react-admin";
import { createMuiTheme } from "@material-ui/core";
import englishMessages from "ra-language-english";
import frenchMessages from "ra-language-french";
import merge from "lodash/merge";
import config from "./config";
import {
  ReadingCenterList,
  ReadingCenterEdit,
  ReadingCenterCreate
} from "./components/reading-centers";
import {
  ReadingUnitList,
  ReadingUnitEdit,
  ReadingUnitCreate
} from "./components/reading-units";
import { UserList, UserEdit, UserCreate } from "./components/users";
import { DeviceList, DeviceEdit, DeviceDetails } from "./components/devices";
import {
  ItineraryList,
  ItineraryDetails
} from "./components/reading-itineraries";
import {
  AnomalyCorrectionItineraryList,
  AnomalyCorrectionItineraryDetails,
  ControlReadingItineraryDetails,
  ControlReadingItineraryList,
  DistributionItineraryList,
  DistributionItineraryDetails,
  TaskDetails
} from "./components/itineraries";
import {
  ReadingTaskList,
  ReadingTaskDetails
} from "./components/reading-tasks";
import {
  AnomalyList,
  AnomalyEdit,
  AnomalyCreate
} from "./components/anomalies";
import {
  DeviceSettingList,
  DeviceSettingEdit
} from "./components/device-settings";
import { SettingList, SettingEdit } from "./components/settings";
import { WorkHoursList, WorkHoursEdit } from "./components/workhours";
import {
  RegisteredMeterList,
  RegisteredMeterCreate,
  RegisteredMeterEdit
} from "./components/registered-meters";
import {
  ClientActivityList,
  ClientActivityCreate,
  ClientActivityEdit
} from "./components/client-activity";
import { MraReducer, authSaga, relationSaga, apiSaga } from "./redux";
import { Layout, Menu } from "./components/layout";
import { Dashboard } from "./components/dashboard";
import { PictureList } from "./components/picture-manager";
import { ReadingCalendarList } from "./components/reading-calendar";
import { restClient, authClient, httpClient, i18n } from "./lib";
import { customRoutes } from "./routes";

// combine default messages from admin-on-rest with our own translations
const messages = {
  fr: merge(frenchMessages, i18n.fr),
  en: merge(englishMessages, i18n.en)
};

// title is used for login page and layout
const title = "Meter Reading Application v1.5.10";

const i18nProvider = locale => messages[locale];
const lightTheme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: "#00bcd4",
      contrastText: "#fff"
    }
  }
});

const LoginPage = props => <Login {...props} backgroundImage={""} />;

// define app routes
const App = () => (
  <Admin
    title={title}
    dataProvider={restClient()}
    authProvider={authClient}
    customRoutes={customRoutes}
    appLayout={Layout}
    customReducers={{ mra: MraReducer }}
    customSagas={[relationSaga(httpClient), apiSaga(httpClient), authSaga]}
    i18nProvider={i18nProvider}
    locale={config.DEFAULT_LOCALE}
    menu={Menu}
    dashboard={Dashboard}
    theme={lightTheme}
    loginPage={LoginPage}
  >
    <Resource
      name={config.READINGCENTER_RESOURCE}
      list={ReadingCenterList}
      create={ReadingCenterCreate}
      edit={ReadingCenterEdit}
    />
    <Resource
      name={config.USER_RESOURCE}
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
    />
    <Resource
      name={config.READINGUNIT_RESOURCE}
      list={ReadingUnitList}
      create={ReadingUnitCreate}
      edit={ReadingUnitEdit}
    />
    <Resource
      name={config.ITINERARY_RESOURCE}
      list={ItineraryList}
      show={ItineraryDetails}
    />
    <Resource
      name={config.DISTRIBUTION_ITINERARY_RESOURCE}
      list={DistributionItineraryList}
      show={DistributionItineraryDetails}
    />
    <Resource
      name={config.ANOMALY_CORRECTION_ITINERARY_RESOURCE}
      list={AnomalyCorrectionItineraryList}
      show={AnomalyCorrectionItineraryDetails}
    />
    <Resource
      name={config.CONTROL_READING_ITINERARY_RESOURCE}
      list={ControlReadingItineraryList}
      show={ControlReadingItineraryDetails}
    />
    <Resource
      name={config.READINGTASK_RESOURCE}
      list={ReadingTaskList}
      show={ReadingTaskDetails}
    />
    <Resource name={config.DISTRIBUTION_TASK_RESOURCE} show={TaskDetails} />
    <Resource
      name={config.ANOMALY_CORRECTION_TASK_RESOURCE}
      show={TaskDetails}
    />
    <Resource name={config.CONTROL_READING_TASK_RESOURCE} show={TaskDetails} />
    <Resource
      name={config.DEVICE_RESOURCE}
      list={DeviceList}
      edit={DeviceEdit}
      show={DeviceDetails}
    />
    <Resource
      name={config.ANOMALIES_RESOURCE}
      list={AnomalyList}
      create={AnomalyCreate}
      edit={AnomalyEdit}
    />
    <Resource
      name={config.DEVICES_SETTINGS_RESOURCE}
      list={DeviceSettingList}
      edit={DeviceSettingEdit}
    />
    <Resource
      name={config.SETTINGS_RESOURCE}
      list={SettingList}
      edit={SettingEdit}
    />
    <Resource
      name={config.WORK_HOURS_RESOURCE}
      list={WorkHoursList}
      edit={WorkHoursEdit}
    />
    <Resource
      name={config.REGISTERED_METERS_RESOURCE}
      list={RegisteredMeterList}
      create={RegisteredMeterCreate}
      edit={RegisteredMeterEdit}
    />
    <Resource
      name={config.CLIENT_ACTIVITY_RESOURCE}
      list={ClientActivityList}
      create={ClientActivityCreate}
      edit={ClientActivityEdit}
    />
    <Resource
      name={config.READING_CALENDAR_RESOURCE}
      list={ReadingCalendarList}
    />
    {/* hidden resources */}
    <Resource name={config.READING_RESOURCE} />
    <Resource name={config.LOCATION} />
    <Resource name={config.TASK_LOCATION} />
    <Resource name={config.DISTRIBUTION_RESOURCE} />
    <Resource name={config.METER_RESOURCE} />
    <Resource name={config.REGION_RESOURCE} />
    <Resource name={config.READING_TYPE_RESOURCE} />
    <Resource name={config.METER_TYPE_RESOURCE} />
    <Resource name={config.ROLE_RESOURCE} />
    <Resource name={config.PERMISSION_RESOURCE} />
    <Resource name={config.IMAGE_RESOURCE} />
    <Resource name={config.QUALITY_RULES_RESOURCE} />
    <Resource name={config.QUALITYRUN_RESOURCE} />
    <Resource name={config.MESSAGES_RESOURCE} />
    <Resource name={config.RMS_RESOURCE} list={PictureList} />
    <Resource name={config.EXPORT_RECON_RESOURCE} />
    <Resource name={config.IMPORT_RECON_RESOURCE} />
    <Resource name={config.TOP_ITINERARY_RESOURCE} />
  </Admin>
);

export default App;
